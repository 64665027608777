import VueJwtDecode from 'vue-jwt-decode';

var secret = "fjassfyvbxjv65673jkdjdbc";


export function getUserInfo() {

    const token = localStorage.getItem("tokenIgreja");
    
    console.log('Token do localstorage', token)
    var decoded = VueJwtDecode.decode(token);

    
    console.log('Decoded', decoded)

    var congregacaoId = decoded.congregacaoId;
    var nivel = decoded.nivel;
    
    return {congregacaoId, nivel}
}

/**
 * userInfo = getUserInfo()
 * userInfo.congregacaoId
 * userInfo..nivel
 * 
 * 
 * const {congregacaoId, usuarioId, nivel} = getUserInfo()
 */