<template>
  <nav class="navbar is-dark" role="navigation" aria-label="main navigation">
    <!-- Navbar Brand and Burger -->
    <div class="navbar-brand">
      <a class="navbar-item" href="#">
        <img src="../../public/img/logo.png" width="80" height="35">
      </a>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarBasicExample"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <!-- Navbar Menu -->
    <div class="navbar-menu" :class="{ 'is-active': menuActive }" id="navbarBasicExample">
      <div class="navbar-start">
        <!-- Dropdown menus -->
        <div  class="navbar-item has-dropdown" :class="{ 'is-active': dropdownActive === 'igrejas' }">  <!-- v-if="[1, 3].includes(nivel)" -->
          <a class="navbar-link" @click="toggleDropdown('igrejas')">Igrejas</a>
          <div class="navbar-dropdown" :class="{ 'is-hidden-touch': dropdownActive !== 'igrejas' }">
            <router-link class="navbar-item" to="/congregacao" @click="closeMenu">Congregações</router-link>
            <router-link class="navbar-item" to="/membro" @click="closeMenu">Membros</router-link>
            <router-link v-if="[3].includes(nivel)" class="navbar-item" to="/usuario" @click="closeMenu">Usuários</router-link>
            <router-link class="navbar-item" to="/" @click="closeMenu">Grupos</router-link>
            <router-link class="navbar-item" to="/" @click="closeMenu">Agenda</router-link>
            <hr class="navbar-divider" />
            <router-link class="navbar-item" to="/membro/relAniversariantes" @click="closeMenu">Aniversáriantes</router-link>
            <router-link class="navbar-item" to="/membro/fichaMembros" @click="closeMenu">Fichas Cadastrais</router-link>
          </div>
        </div>

        <!-- More dropdown menus -->
        <div class="navbar-item has-dropdown" :class="{ 'is-active': dropdownActive === 'financeiro' }">
          <a class="navbar-link" @click="toggleDropdown('financeiro')">Financeiro</a>
          <div class="navbar-dropdown" :class="{ 'is-hidden-touch': dropdownActive !== 'financeiro' }">
            <a class="navbar-item" @click="closeMenu">Dízimos</a>
            <a class="navbar-item" @click="closeMenu">Ofertas</a>
            <a class="navbar-item" @click="closeMenu">Transferência Dízimos/Ofertas</a>
            <a class="navbar-item" @click="closeMenu">Movimento de Caixa</a>
            <hr class="navbar-divider" />
            <a class="navbar-item" @click="closeMenu">Relatórios</a>
          </div>
        </div>

        <!-- Another dropdown -->
        <div class="navbar-item has-dropdown" :class="{ 'is-active': dropdownActive === 'patrimonio' }">
          <a class="navbar-link" @click="toggleDropdown('patrimonio')">Patrimônio</a>
          <div class="navbar-dropdown" :class="{ 'is-hidden-touch': dropdownActive !== 'patrimonio' }">
            <a class="navbar-item" @click="closeMenu">Bens</a>
            <a class="navbar-item" @click="closeMenu">Estoque</a>
            <a class="navbar-item" @click="closeMenu">Empréstimos</a>
            <hr class="navbar-divider" />
          </div>
        </div>
      </div>

      <!-- Navbar End (Actions for authenticated users) -->
      <div class="navbar-end" v-if="isLoggedIn()">
        <div class="navbar-item has-dropdown" :class="{ 'is-active': dropdownActive === 'acoes' }"> <!-- v-if="[1, 3].includes(nivel)" -->
          <a class="navbar-link" @click="toggleDropdown('acoes')">Ações</a>
          <div class="navbar-dropdown" :class="{ 'is-hidden-touch': dropdownActive !== 'acoes' }">
            <router-link class="navbar-item" to="/dadosUsuario" @click="closeMenu">Meus Dados</router-link>
            <a class="navbar-item" @click="logout(); closeMenu()">Logout</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "my-Nav",
  props: ["logo", "alt"],
  data() {
    return {
      nivel: parseInt(localStorage.getItem("nivel")),
      menuActive: false, // Track menu state
      dropdownActive: null, // Track active dropdown
    };
  },
  methods: {
    logout() {
      localStorage.removeItem("tokenIgreja");
      localStorage.removeItem("nivel");
      this.$router.replace("/login");
    },
    isLoggedIn() {
      return localStorage.getItem("tokenIgreja") !== null;
    },
    toggleMenu() {
      this.menuActive = !this.menuActive; // Toggle menu state
    },
    toggleDropdown(dropdownName) {
      if (this.dropdownActive === dropdownName) {
        this.dropdownActive = null; // Close dropdown if already open
      } else {
        this.dropdownActive = dropdownName; // Open selected dropdown
      }
      this.menuActive = false; // Close mobile menu
    },
    closeMenu() {
      this.menuActive = false; // Close mobile menu
      this.dropdownActive = null; // Reset active dropdown
    },
  },
};
</script>

<style scoped>
/* Custom styles for responsiveness */
@media screen and (max-width: 1023px) {
  .navbar-menu.is-active {
    display: block;
  }
  .navbar-menu {
    display: none;
  }
}
</style>
